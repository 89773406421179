import React, { createContext, useContext, useEffect, useState } from "react";
import { collection, getDocs, addDoc, getFirestore } from "firebase/firestore";
import { db } from "../utils/firebase/firebase.utils";

const ProjectsContext = createContext();

export const useProjects = () => useContext(ProjectsContext);

export const ProjectsProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);

  // const updateProject = async (id, updatedData) => {
  //   try {
  //     const projectDoc = doc(db, "projects", id);
  //     await updateDoc(projectDoc, updatedData);
  //     setProjects((prevProjects) =>
  //       prevProjects.map((project) =>
  //         project.id === id ? { ...project, ...updatedData } : project
  //       )
  //     );
  //   } catch (error) {
  //     console.error("Error updating project: ", error);
  //   }
  // };

  const [projects, setProjects] = useState([]);

  const addProject = async (newProject) => {
    try {
      const db = getFirestore();

      const projectsRef = collection(db, "projects");

      const docRef = await addDoc(projectsRef, {
        ...newProject, 
      });

      const newProjectWithId = { id: docRef.id, ...newProject };

      setProjects((prevProjects) => [...prevProjects, newProjectWithId]);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
  
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const projectsCollection = collection(db, "projects");
        const projectsSnapshot = await getDocs(projectsCollection);
        const projectsData = projectsSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .sort((a, b) => (a.order > b.order ? 1 : -1)); // Sorting by the 'order' field

        setProjects(projectsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching projects: ", error);
      }
    };

    fetchProjects();
  }, []);

  return (
    <ProjectsContext.Provider value={{ projects, loading, addProject }}>
      {children}
    </ProjectsContext.Provider>
  );
};

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { db } from "../../utils/firebase/firebase.utils";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGears, faLocationDot, faBarsStaggered, faExpand, faArrowLeft, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./project.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useAuth } from "../../contexts/auth.context";

const ProjectDetails = () => {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [updatedProject, setUpdatedProject] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const storage = getStorage();

  const handleBack = () => {
    navigate("/#projects");
    setTimeout(() => {
      const projectsSection = document.getElementById("projects");
      if (projectsSection) {
        projectsSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const handleEdit = () => {
    setIsEditing(true);
    setUpdatedProject({ ...project });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedProject((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleScopeChange = (e, index) => {
    const { value } = e.target;
    const updatedScopes = [...updatedProject.scope];
    updatedScopes[index] = value;
    setUpdatedProject((prevState) => ({
      ...prevState,
      scope: updatedScopes,
    }));
  };

  const handleAddScope = () => {
    const updatedScopes = [...updatedProject.scope, ""];
    setUpdatedProject((prevState) => ({
      ...prevState,
      scope: updatedScopes,
    }));
  };

  const handleRemoveScope = (index) => {
    const updatedScopes = updatedProject.scope.filter((_, i) => i !== index);
    setUpdatedProject((prevState) => ({
      ...prevState,
      scope: updatedScopes,
    }));
  };

  const handleSave = async () => {
    try {
      const projectDoc = doc(db, "projects", id);
      await updateDoc(projectDoc, updatedProject);
      setProject(updatedProject);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating project:", error);
    }
  };

  const handleAddImage = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsUploading(true);

    try {
      const storageRef = ref(storage, `projects/${id}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("Upload error:", error);
          setIsUploading(false);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const updatedImages = [...project.images, downloadURL];
          const projectDoc = doc(db, "projects", id);

          await updateDoc(projectDoc, { images: updatedImages });
          setProject((prevState) => ({
            ...prevState,
            images: updatedImages,
          }));

          setIsUploading(false);
        }
      );
    } catch (error) {
      console.error("Error uploading image:", error);
      setIsUploading(false);
    }
  };

  const handleRemoveImage = async (indexToRemove) => {
    try {
      const imageToRemove = project.images[indexToRemove];
  
      const urlPath = decodeURIComponent(imageToRemove.split("?")[0]); // Remove query params
      const bucketPath = urlPath.split("/o/")[1]; // Extract the path after '/o/'
  
      const storageRef = ref(storage, bucketPath);
  
      await deleteObject(storageRef);
  
      const updatedImages = project.images.filter((_, index) => index !== indexToRemove);
      const projectDoc = doc(db, "projects", id);
      await updateDoc(projectDoc, { images: updatedImages });

      setProject((prevState) => ({
        ...prevState,
        images: updatedImages,
      }));

    } catch (error) {
      console.error("Error removing image:", error);
    }
  };
  
  useEffect(() => {
    const fetchProject = async () => {
      try {
        const projectDoc = doc(db, "projects", id);
        const projectSnapshot = await getDoc(projectDoc);
        if (projectSnapshot.exists()) {
          setProject(projectSnapshot.data());
        }
      } catch (error) {
        console.error("Error fetching project:", error);
      }
    };

    fetchProject();
  }, [id]);

  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <div className="project-details-container">
      <div className="project-title-back-link">
        <button className="back-link" onClick={handleBack}>
          <FontAwesomeIcon icon={faArrowLeft} /> Back
        </button>
        <div className="project-titlee">
          <h2>{project.name}</h2>
        </div>
      </div>
      <div className="project-content">
        <div className="slideshow-container">
          <Splide
            options={{
              type: "loop",
              perPage: 1,
              autoplay: true,
              pauseOnHover: true,
              interval: 3000,
              arrows: true,
              pagination: true,
              easing: "ease-in-out",
              speed: 1000,
            }}
          >
            {project.images.map((image, index) => (
              <SplideSlide key={index}>
                <div className="project-image-wrapper">
                  <LazyLoadImage
                    src={image}
                    alt={`${project.name} ${index + 1}`}
                    className="project-image"
                  />
                  {currentUser && (
                    <button
                      className="remove-image-btn"
                      onClick={() => handleRemoveImage(index)}
                    >
                      <FontAwesomeIcon icon={faTrash} /> Remove
                    </button>
                  )}
                </div>
              </SplideSlide>
            ))}
          </Splide>
          {currentUser && (
            <div className="add-image">
              <input
                type="file"
                accept="image/*"
                onChange={handleAddImage}
                disabled={isUploading}
              />
              {isUploading && <p>Uploading...</p>}
            </div>
          )}
        </div>

        <div className="project-info">
          {currentUser && isEditing ? (
            <div className="project-edit-form">
              <input
                type="text"
                name="name"
                value={updatedProject.name}
                onChange={handleInputChange}
              />
              <input
                type="text"
                name="location"
                value={updatedProject.location}
                onChange={handleInputChange}
              />
              <input
                type="text"
                name="area"
                value={updatedProject.area}
                onChange={handleInputChange}
              />
              <input
                type="text"
                name="duration"
                value={updatedProject.duration}
                onChange={handleInputChange}
              />
               <div className="scope-edit">
                <h3>Scopes</h3>
                {updatedProject.scope?.map((scope, index) => (
                  <div key={index} className="scope-item">
                    <input
                      type="text"
                      value={scope}
                      onChange={(e) => handleScopeChange(e, index)}
                      placeholder={`Scope ${index + 1}`}
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveScope(index)}
                      className="remove-scope-btn"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                ))}
                <button onClick={handleAddScope} className="add-scope-btn">
                  Add Scope
                </button>
              </div>

              <button onClick={handleSave}>Save</button>
            </div>
          ) : (
            <div className="project-details">
              <div className="project-details-row">
                <p>
                  <FontAwesomeIcon icon={faLocationDot} style={{ color: "#263870" }} className="fa-icon" /> {project.location}
                </p>
                <p>
                  <FontAwesomeIcon icon={faExpand} style={{ color: "#263870" }} className="fa-icon" /> {project.area[0]}
                </p>
                <p>
                  <FontAwesomeIcon icon={faBarsStaggered} style={{ color: "#263870" }} className="fa-icon" /> {project.duration}
                </p>
              </div>
              <h2>Scope</h2>
              <ul>
                {project.scope.map((service, index) => (
                  <li key={index}>
                    <FontAwesomeIcon icon={faGears} style={{ color: "#263870" }} className="fa-icon" /> {service}
                  </li>
                ))}
              </ul>
              {currentUser && !isEditing ? (
                <button onClick={handleEdit}>
                  <FontAwesomeIcon icon={faEdit} /> Edit
                </button>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;

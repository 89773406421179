import React, { useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../page-title/page-title";
import "./projects.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { useProjects } from "../../contexts/projects.context";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useAuth } from "../../contexts/auth.context";

const Projects = () => {
  const { projects, addProject } = useProjects(); // Assuming you have an `addProject` function in your context
  const { currentUser } = useAuth();
  const [showForm, setShowForm] = useState(false);
  const [newProject, setNewProject] = useState({
    name: "",
    date: "",
    description: "",
    duration: "",
    images: [],
    location: "",
    area: ["", ""],
    scope: [],
  });
  const [scopeInput, setScopeInput] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAreaChange = (e, index) => {
    const { value } = e.target;
    setNewProject((prev) => {
      const updatedArea = [...prev.area];
      updatedArea[index] = value;
      return { ...prev, area: updatedArea };
    });
  };

  const handleScopeAdd = () => {
    if (scopeInput.trim()) {
      setNewProject((prev) => ({
        ...prev,
        scope: [...prev.scope, scopeInput.trim()],
      }));
      setScopeInput("");
    }
  };

  const handleSubmit = () => {
    const newProjectData = {
      ...newProject,
      order: projects.length + 1, // Automatically set order
    };
    addProject(newProjectData); // Save the project via the context or API
    setShowForm(false);
    setNewProject({
      name: "",
      date: "",
      description: "",
      duration: "",
      images: [],
      location: "",
      area: ["", ""],
      scope: [],
    });
  };

  return (
    <div className="projects-container">
      <div className="projects-title">
        <PageTitle title="Our Projects" />
        {currentUser &&  (
          <button className="add-project-button" onClick={() => setShowForm(!showForm)}>
            {showForm ? "Cancel" : "Add Project"}
          </button>
        )}
      </div>
      {showForm && currentUser  && (
        <div className="add-project-form">
          <input
            type="text"
            name="name"
            placeholder="Project Name"
            value={newProject.name}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="date"
            placeholder="Project Start Date"
            value={newProject.date}
            onChange={handleInputChange}
            required
          />
          <textarea
            name="description"
            placeholder="Project Description"
            value={newProject.description}
            onChange={handleInputChange}
            required
          ></textarea>
          <input
            type="text"
            name="duration"
            placeholder="Project Duration"
            value={newProject.duration}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="location"
            placeholder="Project Location"
            value={newProject.location}
            onChange={handleInputChange}
            required
          />
          <div className="area-inputs">
            <input
              type="text"
              placeholder="Area in sqm"
              value={newProject.area[0]}
              onChange={(e) => handleAreaChange(e, 0)}
              required
            />
            <input
              type="text"
              placeholder="No. of Floors/Other Info"
              value={newProject.area[1]}
              onChange={(e) => handleAreaChange(e, 1)}
              required
            />
          </div>
          <div className="scope-inputs">
            <input
              type="text"
              placeholder="Scope"
              value={scopeInput}
              onChange={(e) => setScopeInput(e.target.value)}
              required
            />
            <button onClick={handleScopeAdd}>Add Scope</button>
            <ul>
              {newProject.scope.map((scope, index) => (
                <li key={index}>{scope}</li>
              ))}
            </ul>
          </div>
          <button className="submit-project-button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      )}
      <Splide
        className="projects-splide unique-projects-splide"
        options={{
          type: "loop",
          perPage: 3,
          focus: "center",
          pauseOnHover: true,
          autoplay: true,
          gap: "1rem",
          arrows: true,
          pagination: false,
          easing: "ease-in-out",
          speed: 1000,
          width: "95%",
          height: "100%",
          padding: 10,
          interval: 3000,
          updateOnMove: true,
          breakpoints: {
            868: {
              perPage: 1,
            },
          },
        }}
        aria-label="Project Images"
      >
        {projects.map((project, index) => (
          <SplideSlide key={index}>
            <div className="project-slide">
              <div className="project-image-wrapper">
                <LazyLoadImage
                  src={project.images[0]}
                  alt={`${project.name} ${index + 1}`}
                  className="project-image"
                />
                <div className="overlay">
                  <div className="text-content">
                    <h3>{project.name}</h3>
                    <p className="date">{project.date}</p>
                    <p className="description">{project.description}</p>
                    <Link to={`/project/${project.id}`} className="read-more">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};

export default Projects;
